import React from "react";
import "./../../App.css";

const Loader = ({ message }) => {
  return (
    <div id="preloader">
      <div id="prespinner"></div>
      <br />
      <p className="loader_text">{message}</p>
    </div>
  );
};

export default Loader;
