import React, { useState, useEffect, useRef } from "react";
import displaydata from "../../assests/displaydata";
import displaydatatwo from "../../assests/displaydatatwo";
import Loader from "./../Loader/Loader";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";
import Header from "./../Header/Header";

//--------->Material UI Import
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const Comparepage = () => {
  // ---------->Common useState for both compare bodies
  const param = useParams();
  const { addToast } = useToasts();
  const classes = useStyles();
  const pageStartRef = useRef(null);
  const pageEndRef = useRef(null);
  // const user = localStorage.getItem("amazonuser");
  const [selectedfield, setselectedfield] = useState("");
  const [loader, setloader] = useState(false);
  const [showui, setshowui] = useState(true);
  const [backdropopen, setbackdropopen] = useState(false);
  const [subfielderror, setsubfielderror] = useState(false);
  const [secondsubfielderror, setsecondsubfielderror] = useState(false);
  const [getpageno, setgetpageno] = useState();
  const [page, setpage] = useState(1);
  const [apibody, setapibody] = useState();
  const [data, setdata] = useState([]);
  const [latestfield, setlatestfield] = useState("");
  const [tablefield, settablefield] = useState("");
  const [sortorder, setsortorder] = useState("desc");
  const [noofrows, setnoofrows] = useState("10000");
  const [plantag, setplantag] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "all",
  });
  // ---------->useState for first compare body
  const [yearoptionscomparebodyfirst, setyearoptionscomparebodyfirst] =
    useState([]);
  const [subfieldoptionscomparebodyfirst, setsubfieldoptionscomparebodyfirst] =
    useState([]);
  const [weekoptionscomparebodyfirst, setweekoptionscomparebodyfirst] =
    useState([]);
  const [selectedyearcomparebodyfirst, setselectedyearcomparebodyfirst] =
    useState();
  const [
    selectedsubfieldcomparebodyfirst,
    setselectedsubfieldcomparebodyfirst,
  ] = useState("");

  // ---------->useState for second compare body
  const [yearoptionscomparebodysecond, setyearoptionscomparebodysecond] =
    useState([]);
  const [
    subfieldoptionscomparebodysecond,
    setsubfieldoptionscomparebodysecond,
  ] = useState([]);
  const [weekoptionscomparebodysecond, setweekoptionscomparebodysecond] =
    useState([]);
  const [selectedyearcomparebodysecond, setselectedyearcomparebodysecond] =
    useState();
  const [
    selectedsubfieldcomparebodysecond,
    setselectedsubfieldcomparebodysecond,
  ] = useState("");

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    checkuserdetails();
  }, []);

  const checkuserdetails = () => {
    setloader(true);
    let headers = { Authorization: `JWT ${param?.token}` };
    axios
      .get(
        `https://api.importkey.com/auth/us-export/user/details/${param?.customer_id}?email=${param?.email}`,
        { headers }
      )
      .then((res) => {
        if (res.data.status) {
          setplantag(res.data.subscription.tag);
          setyearoptionscomparebodyfirst([]);
          setyearoptionscomparebodysecond([]);
          for (let i = new Date().getFullYear() - 2019; i > 0; i--) {
            setyearoptionscomparebodyfirst((prev) => [
              ...prev,
              new Date().getFullYear() - i,
            ]);
            setyearoptionscomparebodysecond((prev) => [
              ...prev,
              new Date().getFullYear() - i,
            ]);
          }
          setyearoptionscomparebodyfirst((prev) => [
            ...prev,
            new Date().getFullYear(),
          ]);
          setyearoptionscomparebodysecond((prev) => [
            ...prev,
            new Date().getFullYear(),
          ]);
        } else {
          setshowui(false);
          addToast(`Unauthorised!`, {
            appearance: "error",
            autoDismiss: true,
          });
        }
        setTimeout(() => {
          setloader(false);
        }, 1000);
      })
      .catch((err) => {
        window.location.href = "https://importkey.com/login/ended";
      });
  };
  const fieldchange = (e) => {
    setselectedfield(e.target.value);
    setsubfieldoptionscomparebodyfirst([]);
    setsubfieldoptionscomparebodysecond([]);
    setweekoptionscomparebodyfirst([]);
    setweekoptionscomparebodysecond([]);
    setselectedsubfieldcomparebodyfirst("");
    setselectedsubfieldcomparebodysecond("");
    changefirstsubfieldvalue(selectedyearcomparebodyfirst, e.target.value);
    changesecondsubfieldvalue(selectedyearcomparebodysecond, e.target.value);
    setValue("firstsubfield", "");
    setValue("secondsubfield", "");
    setValue("firstweek", "");
    setValue("secondweek", "");
  };

  const firstyearchange = (e) => {
    setselectedyearcomparebodyfirst(e.target.value);
    setsubfieldoptionscomparebodyfirst([]);
    setselectedsubfieldcomparebodyfirst("");
    changefirstsubfieldvalue(e.target.value, selectedfield);
    setValue("firstsubfield", "");
    setValue("firstweek", "");
  };
  const changefirstsubfieldvalue = (datayear, datafield) => {
    if (!datayear || !datafield) {
      return;
    }
    if (datayear < 2021) {
      displaydata[datayear]?.[datafield]?.forEach((element) => {
        setsubfieldoptionscomparebodyfirst((prev) => [...prev, element]);
      });
      return;
    }
    if (datayear < new Date().getFullYear()) {
      let month = Array.from(Array(12), (val, index) => index);
      if (datafield !== "Quarter") {
        month.forEach((item, index) => {
          setsubfieldoptionscomparebodyfirst((prev) => [
            ...prev,
            monthNames[index],
          ]);
        });
      } else {
        let i = 0;
        while (i + 2 < 12) {
          let z = i;
          setsubfieldoptionscomparebodyfirst((prev) => [
            ...prev,
            {
              value: monthNames[z],
              display: `${monthNames[z]} - ${monthNames[z + 2]}`,
            },
          ]);
          i += 3;
        }
      }
    } else {
      let monthnumber = new Date().getMonth();
      let month = Array.from(Array(monthnumber), (val, index) => index);
      if (datafield !== "Quarter") {
        month.forEach((item, index) => {
          setsubfieldoptionscomparebodyfirst((prev) => [
            ...prev,
            monthNames[index],
          ]);
        });
      } else {
        let i = 0;
        while (i + 2 < monthnumber) {
          let z = i;
          setsubfieldoptionscomparebodyfirst((prev) => [
            ...prev,
            {
              value: monthNames[z],
              display: `${monthNames[z]} - ${monthNames[z + 2]}`,
            },
          ]);
          i += 3;
        }
      }
    }
  };
  const firstsubfieldchange = (e) => {
    setweekoptionscomparebodyfirst([]);
    setValue("firstweek", "");
    setselectedsubfieldcomparebodyfirst(e.target.value);
    if (selectedfield === "Week") {
      checkfirstweekoptions(selectedyearcomparebodyfirst, e.target.value);
    }
  };
  const checkfirstweekoptions = (year, subfield) => {
    if (year && subfield) {
      setbackdropopen(true);
      let body = {
        month: subfield,
        year: Number(year),
      };
      axios
        .post(`${base_url}/monthnameandcount`, body, {
          headers: {
            Authorization: `Token ${param?.token}`,
          },
        })
        .then((res) => {
          setweekoptionscomparebodyfirst(res.data.Data || []);
          setbackdropopen(false);
        })
        .catch((err) => {
          if (err.response) {
            if (Number(err.response.status) === 401) {
              if (err.response.data.detail === "Invalid token.") {
                addToast(`Session Expired`, {
                  appearance: "warning",
                  autoDismiss: true,
                });
                setTimeout(() => {
                  window.location.replace("https://importkey.com/dashboard");
                }, 2000);
              }
            }
          }
          setbackdropopen(false);
          setweekoptionscomparebodyfirst([]);
        });
    }
  };
  const secondyearchange = (e) => {
    setselectedyearcomparebodysecond(e.target.value);
    setsubfieldoptionscomparebodysecond([]);
    setselectedsubfieldcomparebodysecond("");
    changesecondsubfieldvalue(e.target.value, selectedfield);
    setValue("secondsubfield", "");
    setValue("secondweek", "");
  };
  const changesecondsubfieldvalue = (datayear, datafield) => {
    if (!datayear || !datafield) {
      return;
    }
    if (datayear < 2021) {
      displaydatatwo[datayear]?.[datafield]?.forEach((element) => {
        setsubfieldoptionscomparebodysecond((prev) => [...prev, element]);
      });
      return;
    }
    if (datayear < new Date().getFullYear()) {
      let month = Array.from(Array(12), (val, index) => index);
      if (datafield !== "Quarter") {
        month.forEach((item, index) => {
          setsubfieldoptionscomparebodysecond((prev) => [
            ...prev,
            monthNames[index],
          ]);
        });
      } else {
        let i = 0;
        while (i + 2 < 12) {
          let z = i;
          setsubfieldoptionscomparebodysecond((prev) => [
            ...prev,
            {
              value: monthNames[z],
              display: `${monthNames[z]} - ${monthNames[z + 2]}`,
            },
          ]);
          i += 3;
        }
      }
    } else {
      let monthnumber = new Date().getMonth();
      let month = Array.from(Array(monthnumber), (val, index) => index);
      if (datafield !== "Quarter") {
        month.forEach((item, index) => {
          setsubfieldoptionscomparebodysecond((prev) => [
            ...prev,
            monthNames[index],
          ]);
        });
      } else {
        let i = 0;
        while (i + 2 < monthnumber) {
          let z = i;
          setsubfieldoptionscomparebodysecond((prev) => [
            ...prev,
            {
              value: monthNames[z],
              display: `${monthNames[z]} - ${monthNames[z + 2]}`,
            },
          ]);
          i += 3;
        }
      }
    }
  };
  const secondsubfieldchange = (e) => {
    setweekoptionscomparebodysecond([]);
    setValue("secondweek", "");
    setselectedsubfieldcomparebodysecond(e.target.value);
    if (selectedfield === "Week") {
      checksecondweekoptions(selectedyearcomparebodysecond, e.target.value);
    }
  };
  const checksecondweekoptions = (year, subfield) => {
    if (year && subfield) {
      setbackdropopen(true);
      let body = {
        month: subfield,
        year: Number(year),
      };
      axios
        .post(`${base_url}/monthnameandcount`, body, {
          headers: {
            Authorization: `Token ${param?.token}`,
          },
        })
        .then((res) => {
          setweekoptionscomparebodysecond(res.data.Data || []);
          setbackdropopen(false);
        })
        .catch((err) => {
          if (err.response) {
            if (Number(err.response.status) === 401) {
              if (err.response.data.detail === "Invalid token.") {
                addToast(`Session Expired`, {
                  appearance: "warning",
                  autoDismiss: true,
                });
                setTimeout(() => {
                  window.location.replace("https://importkey.com/dashboard");
                }, 2000);
              }
            }
          }
          setweekoptionscomparebodysecond([]);
          setbackdropopen(false);
        });
    }
  };

  const handlepage = (event, value) => {
    setloader(true);
    const data = apibody;
    data["pageno"] = value;
    callcompareapi(data, true);
  };

  const comparedata = (data2) => {
    setsubfielderror(true);
    setsecondsubfielderror(true);
    if (!data2.firstsubfield) {
      setsecondsubfielderror(false);
      return;
    }
    if (!data2.secondsubfield) {
      setsubfielderror(false);
      return;
    }
    setsecondsubfielderror(false);
    setsubfielderror(false);
    if (
      data2.firstyear === data2.secondyear &&
      data2.firstsubfield === data2.secondsubfield &&
      data2.firstweek === data2.secondweek
    ) {
      addToast(`Warning!! Please select different value to compare..`, {
        appearance: "warning",
        autoDismiss: true,
      });
      return;
    }

    setnoofrows("10000");
    setloader(true);
    const data = setheaderforapi(data2);
    callcompareapi(data);
  };
  const sortcomparelist = (e) => {
    setloader(true);
    let sortterm = sortorder === "desc" ? "asc" : "desc";
    setsortorder(sortterm);
    let body = apibody;
    body["orderby"] = e.target.id + " " + sortterm;
    setapibody(body);
    callcompareapi(body, true);
  };
  const changerows = (e) => {
    setnoofrows(e.target.value);
    setloader(true);
    let body = apibody;
    body["resultcount"] = Number(e.target.value);
    body["pageno"] = 1;
    setapibody(body);
    callcompareapi(body);
  };
  const setheaderforapi = (data2) => {
    let data = {
      pageno: 1,
      year21: data2.firstyear,
      year22: data2.secondyear,
      searchtype2: data2.field,
      subsearch21: data2.firstsubfield,
      week21: data2.firstweek || "",
      subsearch22: data2.secondsubfield,
      week22: data2.secondweek || "",
      resultcount: Number(noofrows),
      orderby: "Jump_rank desc",
      namesearch2: data2.name,
    };
    setlatestfield(
      `${data2.firstweek || ""}${data2.firstsubfield}${data2.firstyear}`
    );
    settablefield(
      `${data2.secondweek || ""}${data2.secondsubfield}${data2.secondyear}`
    );
    setapibody(data);
    return data;
  };
  const callcompareapi = (data, frompage = false) => {
    axios
      .post(`${base_url}/compare`, data, {
        headers: {
          Authorization: `Token ${param?.token}`,
        },
      })
      .then((res) => {
        setdata(res.data.Data || []);
        setpage(data.pageno);
        if (!frompage) {
          if (Number(res.data.currentresultcount) > 0) {
            setgetpageno(
              Math.ceil(
                Number(res.data.totalnamesearchcount) /
                  Number(res.data.currentresultcount)
              )
            );
          }
        }
        setloader(false);
      })
      .catch((err) => {
        if (err.response) {
          if (Number(err.response.status) === 401) {
            if (err.response.data.detail === "Invalid token.") {
              addToast(`Session Expired`, {
                appearance: "warning",
                autoDismiss: true,
              });
              setTimeout(() => {
                window.location.replace("https://importkey.com/dashboard");
              }, 2000);
            }
          }
        }
        setdata([]);
        setloader(false);
      });
  };

  //----------------------->Showing Toolpit<-----------------------------

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <p>Latest Field</p>
    </Tooltip>
  );
  return (
    <>
      <Header token={param?.token} plantag={plantag} param={param} />
      {loader ? (
        <Loader message={"Please wait, while we are fetching data!!"} />
      ) : (
        <>
          {showui && (
            <>
              <Backdrop className={classes.backdrop} open={backdropopen}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <div ref={pageStartRef} className="startingdiv" />
              <div style={{ marginTop: "80px" }}>
                <i
                  className="fa fa-arrow-circle-up amazon_scrollarrow"
                  aria-hidden="true"
                  onClick={() =>
                    pageStartRef.current?.scrollIntoView({ behavior: "smooth" })
                  }
                ></i>
                <i
                  className="fa fa-arrow-circle-down amazon_scrollarrow2"
                  aria-hidden="true"
                  onClick={() =>
                    pageEndRef.current?.scrollIntoView({ behavior: "smooth" })
                  }
                ></i>
                <h1 className="text-center login_text">
                  Amazon Brand Analysis Compare Data
                </h1>
                <div className="login_container m-4">
                  <form
                    className="display_form"
                    onSubmit={handleSubmit(comparedata)}
                  >
                    {/* <h3 className="login_text">First Data</h3> */}

                    <div className="row mx-1 my-4">
                      <div className="col">
                        <label>Field</label>
                        <select
                          {...register("field", { required: true })}
                          value={selectedfield}
                          className="form-control"
                          onChange={fieldchange}
                        >
                          <option value=""> - Select Field -</option>
                          <option value="Month">Month</option>
                          <option value="Week">Week</option>
                          <option value="Quarter">Quarter</option>
                        </select>
                        {errors.field && (
                          <span className="errormessage">
                            <span>
                              <i
                                className="fa fa-times errorifont"
                                aria-hidden="true"
                              ></i>
                            </span>
                            Field is required
                          </span>
                        )}
                      </div>
                      <div className="col">
                        <label>Year (Most Recent)</label>
                        <select
                          {...register("firstyear", { required: true })}
                          className="form-control"
                          onChange={firstyearchange}
                        >
                          <option value=""> - Select Year -</option>
                          {yearoptionscomparebodyfirst?.map((item, index) => (
                            <option key={index + 1} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                        {errors.firstyear && (
                          <span className="errormessage">
                            <span>
                              <i
                                className="fa fa-times errorifont"
                                aria-hidden="true"
                              ></i>
                            </span>
                            Year is required
                          </span>
                        )}
                      </div>

                      <div className="col">
                        <div className="d-flex mx-1">
                          <label>SubField</label>
                          <OverlayTrigger
                            // placement="right"
                            delay={{ show: 100, hide: 250 }}
                            overlay={renderTooltip}
                          >
                            <img
                              alt="Latest Field"
                              src="./what_img.png"
                              width={15}
                              height={15}
                              style={{ marginTop: "4px", marginLeft: "5px" }}
                            />
                          </OverlayTrigger>
                        </div>
                        <select
                          {...register("firstsubfield")}
                          value={selectedsubfieldcomparebodyfirst}
                          onChange={firstsubfieldchange}
                          className="form-control"
                        >
                          <option value=""> - Select SubField -</option>
                          {selectedfield === "Quarter" ? (
                            <>
                              {subfieldoptionscomparebodyfirst?.map(
                                (item, index) => (
                                  <option key={index + 1} value={item.value}>
                                    {item.display}
                                  </option>
                                )
                              )}
                            </>
                          ) : (
                            <>
                              {subfieldoptionscomparebodyfirst?.map(
                                (item, index) => (
                                  <option key={index + 1} value={item}>
                                    {item}
                                  </option>
                                )
                              )}
                            </>
                          )}
                        </select>
                        {subfielderror && (
                          <span className="errormessage">
                            <span>
                              <i
                                className="fa fa-times errorifont"
                                aria-hidden="true"
                              ></i>
                            </span>
                            SubField is required
                          </span>
                        )}
                      </div>
                      {selectedfield === "Week" ? (
                        <div className="col">
                          <label>Week</label>
                          <select
                            {...register("firstweek", { required: true })}
                            className="form-control"
                          >
                            <option value=""> - Select Week -</option>
                            {weekoptionscomparebodyfirst?.map((item, index) => (
                              <option
                                key={index + 1}
                                value={`Week${index + 1}`}
                              >
                                {`Week${index + 1}`} ({item.start})
                              </option>
                            ))}
                          </select>
                          {errors.firstweek && (
                            <span className="errormessage">
                              <span>
                                <i
                                  className="fa fa-times errorifont"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              Week is required
                            </span>
                          )}
                        </div>
                      ) : null}
                    </div>

                    {/* <h3 className="login_text my-4">Second Data</h3> */}
                    <div className="row mx-1 my-4">
                      <div className="col">
                        <label>Name</label>
                        <input
                          {...register("name")}
                          className="form-control"
                          placeholder="Enter Name"
                        />
                      </div>
                      <div className="col">
                        <label>Year (Previous)</label>
                        <select
                          {...register("secondyear", { required: true })}
                          className="form-control"
                          onChange={secondyearchange}
                        >
                          <option value=""> - Select Year -</option>
                          {yearoptionscomparebodysecond?.map((item, index) => (
                            <option key={index + 1} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                        {errors.secondyear && (
                          <span className="errormessage">
                            <span>
                              <i
                                className="fa fa-times errorifont"
                                aria-hidden="true"
                              ></i>
                            </span>
                            Year is required
                          </span>
                        )}
                      </div>

                      <div className="col">
                        <label>SubField</label>
                        <select
                          {...register("secondsubfield")}
                          value={selectedsubfieldcomparebodysecond}
                          onChange={secondsubfieldchange}
                          className="form-control"
                        >
                          <option value=""> - Select SubField -</option>
                          {selectedfield === "Quarter" ? (
                            <>
                              {subfieldoptionscomparebodysecond?.map(
                                (item, index) => (
                                  <option key={index + 1} value={item.value}>
                                    {item.display}
                                  </option>
                                )
                              )}
                            </>
                          ) : (
                            <>
                              {subfieldoptionscomparebodysecond?.map(
                                (item, index) => (
                                  <option key={index + 1} value={item}>
                                    {item}
                                  </option>
                                )
                              )}
                            </>
                          )}
                        </select>
                        {secondsubfielderror && (
                          <span className="errormessage">
                            <span>
                              <i
                                className="fa fa-times errorifont"
                                aria-hidden="true"
                              ></i>
                            </span>
                            SubField is required
                          </span>
                        )}
                      </div>
                      {selectedfield === "Week" ? (
                        <div className="col">
                          <label>Week</label>
                          <select
                            {...register("secondweek", { required: true })}
                            className="form-control"
                          >
                            <option value=""> - Select Week -</option>
                            {weekoptionscomparebodysecond?.map(
                              (item, index) => (
                                <option
                                  key={index + 1}
                                  value={`Week${index + 1}`}
                                >
                                  {`Week${index + 1}`} ({item.start})
                                </option>
                              )
                            )}
                          </select>
                          {errors.secondweek && (
                            <span className="errormessage">
                              <span>
                                <i
                                  className="fa fa-times errorifont"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              Week is required
                            </span>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <div className="col w-100 row justify-content-end mx-0">
                      <button className="btn display_btn mx-0" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>

                  <div className="row displaytablerow">
                    {data.length > 0 ? (
                      <>
                        <div className="row my-4 mx-3 w-100">
                          <div className="col">
                            <label>No. of Rows</label>
                            <select
                              value={noofrows}
                              onChange={changerows}
                              className="form-control w-25"
                            >
                              <option value="1000">1000</option>
                              <option value="2000">2000</option>
                              <option value="3000">3000</option>
                              <option value="4000">4000</option>
                              <option value="5000">5000</option>
                              <option value="10000">10000</option>
                            </select>
                          </div>
                          <div
                            className="col row justify-content-end align-content-end"
                            style={{ width: "100%" }}
                          >
                            <Pagination
                              count={getpageno}
                              page={page}
                              onChange={handlepage}
                              color="primary"
                            />
                          </div>
                        </div>
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead
                              style={{ backgroundColor: "cornflowerblue" }}
                            >
                              <TableRow>
                                <TableCell>SN</TableCell>
                                <TableCell className="displaytable_bold">
                                  Search Term
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className=" displaytable_bold"
                                >
                                  {tablefield}
                                  <i
                                    id={tablefield}
                                    onClick={sortcomparelist}
                                    className="fa fa-sort comparedata_sorticon"
                                    aria-hidden="true"
                                  ></i>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className=" displaytable_bold"
                                >
                                  {latestfield}
                                  <i
                                    id={latestfield}
                                    onClick={sortcomparelist}
                                    className="fa fa-sort comparedata_sorticon"
                                    aria-hidden="true"
                                  ></i>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className=" displaytable_bold"
                                >
                                  Jump_rank
                                  <i
                                    id="Jump_rank"
                                    onClick={sortcomparelist}
                                    className="fa fa-sort comparedata_sorticon"
                                    aria-hidden="true"
                                  ></i>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data?.map((item, index) => (
                                <TableRow key={index + 1}>
                                  <TableCell>
                                    {(Number(apibody?.pageno) - 1) * 1000 +
                                      index +
                                      1}
                                  </TableCell>
                                  <TableCell>{item.Search_Term}</TableCell>
                                  <TableCell align="center">
                                    {item[tablefield]}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item[latestfield]}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item["Jump_rank"]}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : (
                      <p className="displaynodataavailabletext">
                        No Data Available
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div ref={pageEndRef} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Comparepage;
