import React from "react";
// import DisplaydataPage from "./component/Display/DisplaydataPage";
import Comparepage from "./component/Compare/Comparepage";
import { Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-credit-cards/es/styles-compiled.css";
import "./App.css";

const App = () => {
  return (
    <div>
      <Switch>
        <Route path="/compare/:token/:customer_id/:email">
          <Comparepage />
        </Route>
        {/* <Route path="/display/:token/:customer_id/:email">
          <DisplaydataPage />
        </Route> */}
      </Switch>
    </div>
  );
};

export default App;
