import React from "react";
import Navbar from "react-bootstrap/Navbar";
// import { useHistory } from "react-router-dom";

const Header = ({ token, whattoshow, plantag, param }) => {
  // const history = useHistory();
  // const displaypage = () => {
  //   history.push(`/display/${token}/${param?.customer_id}/${param?.email}`);
  // };
  // const comparepage = () => {
  //   history.push(`/compare/${token}/${param?.customer_id}/${param?.email}`);
  // };
  const redirecttoimportdata = () => {
    // if (plantag === "import_data") {
    window.location.replace("https://importkey.com/dashboard");
    // } else {
    //   window.location.replace(
    //     "https://global.importkey.com/" +
    //       "dashboard?arena=" +
    //       param?.customer_id +
    //       "&salt=" +
    //       param?.token +
    //       "&email=" +
    //       param?.email
    //   );
    // }
  };

  return (
    <Navbar className="header" collapseOnSelect expand="lg">
      <Navbar.Brand>
        <img alt="Logo" src="./importkeylogo.png" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <p className="headerlearnmore" onClick={redirecttoimportdata}>
          {plantag ? "US Import Data" : null}
        </p>
        {/* {whattoshow === "compare" ? (
        <p className="headerlearnmore" onClick={comparepage}>
          Compare
        </p>
        ) : (
          <p className="headerlearnmore" onClick={displaypage}>
            Display
          </p>
        )} */}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
