const displaydata = {
  2019: {
    Month: [
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    Week: ["No Data"],
    Quarter: [
      {
        value: "January",
        display: `January - March`,
      },
      {
        value: "April",
        display: `April - June`,
      },
      {
        value: "July",
        display: `July - September`,
      },
      {
        value: "October",
        display: `October - December`,
      },
    ],
  },
  2020: {
    Month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    Week: ["July", "August", "September", "October", "November", "December"],
    Quarter: [
      {
        value: "January",
        display: `January - March`,
      },
      {
        value: "April",
        display: `April - June`,
      },
      {
        value: "July",
        display: `July - September`,
      },
      {
        value: "October",
        display: `October - December`,
      },
    ],
  },
  2021: {
    Month: ["January", "February", "March", "April", "May", "June", "July"],
    Week: ["January", "February", "March", "April", "May", "June", "July"],
    Quarter: [
      {
        value: "January",
        display: `January - March`,
      },
      {
        value: "April",
        display: `April - June`,
      },
      {
        value: "July",
        display: `July - September`,
      },
    ],
  },
};

export default displaydata;
